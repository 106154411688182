<script>
  import { onMount } from 'svelte';
  import { readable } from 'svelte/store';
  import firebase from '@firebase/app';
  import '@firebase/analytics';
  import '@firebase/firestore';
  import covidCounts from '../data/covid_counts.json';
  // import hospitals from '../data/hospitals.json';
  import states from '../data/us-states.json';
  import config from './config';
  import * as dc from 'dc';
  import crossfilter from 'crossfilter2';
  import {
    csvParse,
    scaleLinear,
    scaleQuantize,
    scaleQuantile,
    scaleLog,
    scalePow,
    scaleSqrt,
    select as selection,
    timeFormat,
    timeParse,
    format as formatNumber,
    geoAlbersUsa,
    scaleTime,
    timeDay,
    timeDays,
    timeMonths,
    timeWeek,
    extent,
  } from 'd3';
  import { get, flatMap, map, maxBy, head, sortBy, orderBy, uniqBy, isNumber } from 'lodash-es';
  import numeral from 'numeral';

  // components
  import Button from '@carevoyance/ui/src/components/Button.svelte';
  import Table from '@carevoyance/ui/src/components/Table.svelte';
  import Modal from '@carevoyance/ui/src/components/Modal.svelte';
  import calculateHospitalScore from './score.ts';
  import HospitalScorecard from './HospitalScorecard.svelte';
  // import Modal from './Modal.svelte';
  // const { open } = getContext('simple-modal');

  // Initialize Firebase
  firebase.initializeApp(config.fbConfig);
  firebase.analytics();

  let currentZoomValue = 'US-wide';
  const db = firebase.firestore();
  const hospitals = db.collection('hospitals');

  const oneDay = 24 * 60 * 60 * 1000;
  let innerWidth = window.innerWidth;
  $: fullWidth = Math.min(innerWidth, 1600);
  $: halfWidth = fullWidth > 600 ? fullWidth / 2 - 40 : fullWidth;
  // onMount(loadMap);
  // function loadMap() {
  //   map = L.map(mapDiv).setView([51.505, -0.09], 13);
  //
  //   L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  //     attribution:
  //       '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  //   }).addTo(map);
  // }

  // console.log("counts", covidCounts);
  // console.log("hospitals", hospitals);

  const stateNames = config.stateNames;

  // charts
  let daySelector = null;
  let statesChart = null;
  let stateRowChart = null;
  // const fipsRowChart = new dc.RowChart('#fips-rows');
  let casesByDayChart = null;
  let counties = null;

  let syncingFilters = false;
  function stateFilterSelection(chart, filter) {
    if (syncingFilters) {
      // Prevent infinite recursion.
      return;
    }

    try {
      syncingFilters = true;
      for (let c of [statesChart, stateRowChart]) {
        if (c !== chart) {
          c.filter(filter);
        }
      }
    } finally {
      syncingFilters = false;
    }
    return filter;
  }

  function remove_empty_bins(source_group) {
    return {
      all: function() {
        return source_group.all().filter(function(d) {
          //return Math.abs(d.value) > 0.00001; // if using floating-point numbers
          return d.value !== 0; // if integers only
        });
      },
    };
  }

  const smallDateFormatSpecifier = '%m-%d';
  const dateFormatSpecifier = '%Y-%m-%d';
  const dateFormat = timeFormat(dateFormatSpecifier);
  const dateFormatParser = timeParse(dateFormatSpecifier);
  const smallDateFormat = timeFormat(smallDateFormatSpecifier);
  const numberFormat = formatNumber(',.0f');
  const decimalFormat = formatNumber(',.2f');
  let allDays = new Set();

  let numCounties = covidCounts.length;
  let scoredCounties = sortBy(
    covidCounts.map((county) => {
      let latestCounts = maxBy(county.data, 'dt');
      return {
        fips: county.fips,
        icu_beds: county.icu_beds,
        hospital_beds: county.hospital_beds,
        pop: county.popestimate2019,
        pop_deaths: county.deaths2019,
        icu_beds_100: (county.icu_beds / county.popestimate2019) * 100000,
        county_name: county.county_name,
        state_abbr: county.state_abbr,
        state_name: stateNames[county.state_abbr],
        state_lat: +county.state_lat,
        state_lon: +county.state_lon,
        county_lat: +county.county_lat,
        county_lon: +county.county_lon,
        latestCounts,
        counts: county.data,
        impact: latestCounts.d / county.icu_beds,
      };
    }),
    'impact'
  ).map((c, index) => {
    c.impactRank = (100 * index) / numCounties;
    return c;
  });

  const countyLookup = {};
  for (let county of scoredCounties) {
    countyLookup[county.fips] = county;
  }

  const formattedCovidData = flatMap(scoredCounties, (county) => {
    return map(county.counts, (data) => {
      allDays.add(data.dt);
      return {
        active: data.a,
        confirmed: data.c,
        deaths: data.d,
        recovered: data.r,
        date: dateFormatParser(data.dt),
        county,
      };
    });
  });

  const headerClass =
    'px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-right';
  const cellClass =
    'px-2 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500';

  const countyTableColumns = [
    {
      id: 'county_name',
      label: 'County',
      sortable: true,
      headerClass:
        'px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider',
      cellClass:
        'px-2 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900',
      value: (c) => c.county.county_name,
    },
    {
      id: 'confirmed',
      label: 'Confirmed cases',
      sortable: true,
      sortDescending: true,
      headerClass,
      cellClass,
    },
    {
      id: 'deaths',
      label: 'Deaths',
      sortable: true,
      sortDescending: true,
      headerClass,
      cellClass,
    },
    {
      id: 'icu_beds_100',
      label: 'ICU Beds per 100,000',
      sortable: true,
      sortDescending: true,
      headerClass,
      cellClass,
      value: (c) => c.county.icu_beds_100,
    },
    // No recovery numbers for the USA in the data we have yet.
    // {
    //   id: 'recovered',
    //   label: 'Recovered',
    //   sortable: true,
    // },
    // {
    //   id: 'button',
    //   label: '',
    // },
  ];

  function crossFilterStore(cf, filterFn) {
    return readable(filterFn(), (set) => {
      let dispose = cf.onChange(() => set(filterFn()));
      return dispose;
    });
  }

  const ndx = crossfilter(formattedCovidData);
  const all = ndx.groupAll();

  // dimensions
  let fipsDimension = ndx.dimension((d) => [
    d.county.state_abbr,
    d.county.county_name,
  ]);
  let stateDimension = ndx.dimension((d) => d.county.state_abbr);
  let dayDimension = ndx.dimension((d) => d.date);

  // groups
  let confirmedCases = null;
  let fipsConfirmed = null;

  let activeCounties = crossFilterStore(ndx, () => fipsDimension.top(Infinity));

  let dayGroup = dayDimension
    .group()
    .reduceSum((d) => d.confirmed)
    .order((d) => d.confirmed);
  let dayDeathsGroup = dayDimension
    .group()
    .reduceSum((d) => d.deaths)
    .order((d) => d.deaths);

  let earliestDay = head(dayDimension.bottom(1)).date;
  let latestDay = head(dayDimension.top(1)).date;

  let viewTypes = {
    confirmed: 'confirmed',
    deaths: 'deaths',
    icu_beds_100: 'icu_beds_100',
  };

  let currentMetric = viewTypes.icu_beds_100;

  function getCurrentViewLabel(chartType) {
    if (currentMetric === viewTypes.confirmed) {
      return 'Confirmed cases';
    } else if (currentMetric === viewTypes.deaths) {
      return 'Deaths';
    } else if (currentMetric === viewTypes.icu_beds_100) {
      return !chartType ? 'ICU Beds/100k' : 'ICU Beds/100k';
    }
  }

  function getCurrentColorDomain() {
    if (currentMetric === viewTypes.confirmed) {
      return [0, 10000];
    } else if (currentMetric === viewTypes.deaths) {
      return [0, 700];
    } else if (currentMetric === viewTypes.icu_beds_100) {
      return [0, 25];
    }
  }

  function getCurrentColors() {
    if (currentMetric === viewTypes.icu_beds_100) {
      return [
        '#d73027',
        '#f46d43',
        '#fdae61',
        '#fee08b',
        '#ffffbf',
        '#d9ef8b',
        '#a6d96a',
        '#66bd63',
        '#1a9850',
      ];
    } else {
      return [
        '#ffffcc',
        '#ffeda0',
        '#fed976',
        '#feb24c',
        '#fd8d3c',
        '#fc4e2a',
        '#e31a1c',
        '#bd0026',
        '#800026',
      ];
    }
  }

  function getCurrentRawValue(d) {
    const value = isNumber(d.value) ? d.value : d.value[currentMetric];
    return value;
  }

  function getCurrentValue(d) {
    if (!d.value) {
      return 0;
    }
    const value = getCurrentRawValue(d);
    return currentMetric === viewTypes.icu_beds_100 ? decimalFormat(value) : numberFormat(value);
  }

  function configureGroups(rerun = null) {
    if (rerun) {
      currentMetric = rerun;
    }
    confirmedCases = stateDimension
      .group()
      .reduce(
        (p, v, nf) => {
          p.icu_beds += +v.county.icu_beds;
          p.pop += +v.county.pop;
          p.deaths += v.deaths;
          p.confirmed += v.confirmed;
          p.icu_beds_100 = (p.icu_beds / p.pop) * 100000;
          return p;
        }, // add
        (p, v, nf) => {
          p.icu_beds -= +v.county.icu_beds;
          p.pop -= +v.county.pop;
          p.deaths -= v.deaths;
          p.confirmed -= v.confirmed;
          p.icu_beds_100 = (p.icu_beds / p.pop) * 100000;
          return p;
        }, // remove
        () => {
          return {
            icu_beds: 0,
            pop: 0,
            deaths: 0,
            confirmed: 0,
            icu_beds_100: 0,
          };
        }, // start
      );
      //.reduceSum((d) => get(d, currentMetric));
    fipsConfirmed = remove_empty_bins(
      fipsDimension.group((d) => d).reduceSum((d) => get(d, currentMetric))
    );

    statesChart.valueAccessor(d => {
      return d.value[currentMetric];
    });

    stateRowChart.valueAccessor(d => {
      return d.value[currentMetric];
    });

    if (rerun) {
      initApp();
    }
  }

  function initApp() {
    daySelector = new dc.SelectMenu('#day-selector');
    statesChart = new dc.GeoChoroplethChart('#us-chart');
    stateRowChart = new dc.RowChart('#state-rows');
    // const fipsRowChart = new dc.RowChart('#fips-rows');
    casesByDayChart = new dc.CompositeChart('#day-chart');
    // counties = new dc.DataTable('#fips-rows');

    configureGroups(false);

    // chart config
    const oldDateHandler = daySelector.filterHandler();

    daySelector
      .title((d) => {
        return `${smallDateFormat(d.key)}: ${numberFormat(d.value)} cases`;
      })
      .filterHandler(function(dimension, filters) {
        const parseFilters = filters.map((d) => new Date(d));
        oldDateHandler(dimension, parseFilters);
        return filters;
      })
      .promptText('Latest')
      .promptValue([latestDay])
      .dimension(dayDimension)
      .group(dayGroup)
      .controlsUseVisibility(true);

    statesChart
      .width(960)
      .height(500)
      .useViewBoxResizing(true)
      .dimension(stateDimension)
      .group(confirmedCases)
      .colors(scaleQuantize().range(getCurrentColors()))
      .colorDomain(getCurrentColorDomain())
      .overlayGeoJson(states.features, 'state', (d) => d.properties.name)
      .projection(geoAlbersUsa())
      .on('filtered', stateFilterSelection)
      .title(
        (d) =>
          'State: ' +
          d.key +
          '\n' +
          getCurrentViewLabel('stateChart') +
          ': ' +
          getCurrentValue(d)
      );

    statesChart.filterPrinter((filters) => {});

    stateRowChart
      .useViewBoxResizing(true)
      .dimension(stateDimension)
      .group(confirmedCases)
      .height(confirmedCases.all().length * 20 + 50)
      .margins({ top: 10, right: 20, bottom: 20, left: 20 })
      .width(375)
      .renderLabel(true)
      .elasticX(true)
      .ordinalColors(['#fc9272'])
      .label(
        (d) =>
          `${d.key}: ${getCurrentValue(d)} ${getCurrentViewLabel(
            'rowChart'
          )}`
      )
      .title(
        (d) =>
          'State: ' +
          d.key +
          '\n' +
          getCurrentViewLabel('rowChart') +
          ': ' +
          getCurrentValue(d)
      )
      .on('filtered', stateFilterSelection)
      .ordering(function(d) { return -getCurrentRawValue(d); });

    stateRowChart.xAxis().tickFormat((d) => numeral(d).format('0a'));
    stateRowChart.xAxis().ticks(10);

    // fipsRowChart
    //   .dimension(fipsDimension)
    //   .group(fipsConfirmed)
    //   .width(halfWidth)
    //   .height(d => {
    //     return fipsConfirmed.all().length * 20 + 50
    //   })
    //   .fixedBarHeight(true)
    //   .margins({ top: 10, right: 20, bottom: 20, left: 20 })
    //   .renderLabel(true)
    //   .elasticX(true)
    //   .ordinalColors(['#fc9272'])
    //   .label(d => `${d.key}: ${numberFormat(d.value)} confirmed cases`)
    //   .title((d) => "County: " + d.key + "\nConfirmed cases: " + numberFormat(d.value ? d.value : 0))
    //   .on('renderlet', function(chart, filter){
    //   });

    // counties
    //   .height(480)
    //   .showSections(false)
    //   .dimension(fipsDimension)
    //   .columns([
    //     {
    //       label: 'County',
    //       format: (d) => d.county_name,
    //     },
    //     {
    //       label: 'Confirmed cases',
    //       format: (d) => numberFormat(d.confirmed),
    //     },
    //     {
    //       label: 'Deaths',
    //       format: (d) => numberFormat(d.deaths),
    //     },
    //     {
    //       label: 'Recovered',
    //       format: (d) => numberFormat(d.recovered),
    //     },
    //     {
    //       label: '',
    //       format: (d) => {
    //         return `<button on:click="{handleClick}">View Hospitals️</button>`;
    //       },
    //     },
    //   ])
    //   .size(150)
    //   .sortBy((d) => -d.confirmed)
    //   .on('renderlet', (chart) => {
    //     chart.selectAll('tr.dc-table-row').on('click', (d) => {
    //       getHospitalsForFips(d.fips);
    //     });
    //   });

    const deathChart = new dc.LineChart(casesByDayChart)
      .group(dayDeathsGroup, 'Deaths')
      .title(function(d) {
        let value = d.value;
        if (isNaN(value)) {
          value = 0;
        }
        return dateFormat(d.key) + '\n' + numberFormat(value);
      })
      .ordinalColors(['red'])
      .useRightYAxis(true);

    const confirmedChart = new dc.BarChart(casesByDayChart)
      .group(dayGroup, 'Confirmed cases')
      .ordinalColors(['#fc9272'])
      .centerBar(true);

    casesByDayChart
      .useViewBoxResizing(true)
      .yAxisLabel('Confirmed cases')
      .dimension(dayDimension)
      .margins({ top: 50, right: 40, bottom: 40, left: 50 })
      .legend(
        dc
          .legend()
          .x(70)
          .y(50)
          .itemHeight(13)
          .gap(5)
      )
      .width(halfWidth)
      .height(250)
      .x(scaleTime().domain([earliestDay.getTime(), latestDay.getTime()]))
      .clipPadding(20)
      .xUnits((start, end) => allDays.size)
      .brushOn(false)
      .elasticY(true)
      .label((d) => `${numberFormat(d.data.value)}`)
      .compose([confirmedChart, deathChart])
      .rightYAxisLabel('Deaths')
      .elasticX(true)
      .xAxisPadding(1)
      .xAxisPaddingUnit('day');

    casesByDayChart.yAxis().tickFormat((d) => numeral(d).format('0a'));
    casesByDayChart.rightYAxis().tickFormat((d) => numeral(d).format('0a'));

    console.log('finished processing');

    daySelector.replaceFilter([[latestDay]]);
    dc.renderAll();
  }

  function resetCharts() {
    dc.filterAll();
    daySelector.replaceFilter([[latestDay]]);
    dc.renderAll();
  }

  function logistic(x) {
    return 1 / (1 + Math.exp(-x));
  }

  function getHospitalsForFips(fips, sortKey) {
    if (!fips) {
      return Promise.resolve([]);
    }

    return hospitals
      .where('fips', '==', fips)
      .get()
      .then(function(querySnapshot) {
        let county = countyLookup[fips];
        let hosps = [];
        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots

          let hosp = doc.data();
          let score = calculateHospitalScore(hosp);

          let profitability =
            Math.max(score.financials.days_cash_on_hand || 0, 1) *
            logistic(score.financials.operating_margin || 0);

          let impactScore = county.impactRank / profitability;

          hosps.push({
            ...hosp,
            county,
            score,
            profitability,
            impactScore: isFinite(impactScore) ? impactScore : 0,
          });
        });

        return hosps;
      })
      .then((hospitals) => {
        //hospitals.sort((a, b) => b.impact - a.impact)
        return uniqBy(hospitals, hosp => hosp._id);
      })
      .catch(function(error) {
        console.log('Error getting documents: ', error);
      });
  }

  let hospitalSort = 'impactScore';
  let hospitalSortDescending = true;
  let currentFips;
  $: currentHospitalsUnsorted = getHospitalsForFips(currentFips);
  $: currentHospitals = currentHospitalsUnsorted.then((hosps) =>
    orderBy(hosps, hospitalSort, hospitalSortDescending ? 'desc' : 'asc')
  );

  function loadHospitals(fips) {
    currentView = VIEW_HOSPITAL;
    currentFips = fips;
  }

  let currentHospital = null;
  let showHospitalModal = false;
  function showHospitalScoreCard(hospital) {
    currentHospital = hospital;
    showHospitalModal = true;
  }
  const VIEW_COUNTIES = 'counties';
  const VIEW_HOSPITAL = 'hospitals';
  let currentView = VIEW_COUNTIES;

  onMount(initApp);
</script>

<svelte:window bind:innerWidth />

<svelte:head>
  <script
    type="text/javascript"
    src="https://platform-api.sharethis.com/js/sharethis.js#property=5e8d258c4749a2001295a0f5&product=inline-share-buttons"
    async="async">

  </script>
</svelte:head>

<div class="relative overflow-auto">

  <nav class="bg-grey-darkest p-2 mt-0 fixed w-full z-10 pin-t bg-white border-gray-200 border-b-2">
    <div class="container mx-auto flex flex-wrap items-center">
      <div class="flex w-full  justify-center  text-white font-extrabold">
        <a class="text-white no-underline hover:text-white hover:no-underline" href="https://www.carevoyance.com/blog/will-hospitals-stay-afloat-during-coronavirus?source=covid19">
          <img alt="Carevoyance logo" src="https://www.carevoyance.com/uploads/6/4/0/2/64025067/published/logos-transparent-1.png?source=covid19">
        </a>
      </div>
    </div>
  </nav>

  <div class="max-w-7xl mx-auto">
    <div class="text-center pt-32 ">
      <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900
      sm:text-5xl sm:leading-none md:text-6xl">
        Impact of <span class="text-red-600">COVID-19</span> on hospital finances
      </h2>
      <p class="mt-2 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl mb-4">
        The <strong>Carevoyance Hospital Financial Health scorecard</strong>
        allows users to assess a variety of relevant criteria to a hospital’s
        financial well-being, given the current impact of the coronavirus
        pandemic in that hospital’s local community.
      </p>
      <p class="mt-2 max-w-md mx-auto text-base text-gray-500 sm:text-sm md:mt-5 md:max-w-3xl mb-4">
        <em>Note:</em> This data is sourced from <a href="https://www.cms.gov/Research-Statistics-Data-and-Systems/Downloadable-Public-Use-Files/Cost-Reports" target="_blank">Centers for Medicare & Medicaid Services</a>
        and <a href="https://systems.jhu.edu/research/public-health/ncov/" target="_blank">Johns Hopkins University Center for Systems Science and Engineering (CSSE)</a>.
        We take care to run quality assurance on both the CMS and JHU data, but, we cannot guarantee the contents of either data source. If you see an error in the data, please feel free to
        contact us via <a href="mailto:support+covid19@carevoyance.com">email</a> or use the chat tool below.
      </p>
      <div class="sharethis-inline-share-buttons"></div>
    </div>

    <div class="flex mt-8 pl-0 sm:pl-12">
      <div class="hidden w-1 md:w-1/2 lg:w-1/3 md:block w-full pr-12">
        <div id="state-rows" class="cell" />
      </div>
      <div class="mb-12 w-1 md:w-1/2 lg:w-2/3 lg:mb-0 w-full mr-4">
        <div class="px-2 mt-3 flex items-center">
          <!--        <div id="day-selector" class="flex-grow" />-->
        </div>
        <div>
          <div class="cell large" style="text-align: center">
            <Button
                    color="normal"
                    size="sm"
                    class="ml-2 sm:ml-4"
                    on:click={resetCharts}>
              Reset
            </Button>
            <Button
                    color={currentMetric === viewTypes.confirmed ? 'primary' : 'secondary'}
                    size="sm"
                    on:click={() => configureGroups(viewTypes.confirmed)}
                    class="ml-2 sm:ml-4 mt-4">
              Confirmed Cases
            </Button>
            <Button
                    color={currentMetric === viewTypes.deaths ? 'primary' : 'secondary'}
                    size="sm"
                    on:click={() => configureGroups(viewTypes.deaths)}
                    class="ml-2 sm:ml-4 mt-4">
              Deaths
            </Button>
            <Button
                    color={currentMetric === viewTypes.icu_beds_100 ? 'primary' : 'secondary'}
                    size="sm"
                    on:click={() => configureGroups(viewTypes.icu_beds_100)}
                    class="ml-2 sm:ml-4 mt-4">
              ICU Beds per 100k
            </Button>
            <div id="us-chart" />
          </div>
          <div id="day-chart" />
        </div>

        {#if currentView === VIEW_COUNTIES}
          <div class="flex px-2 justify-end">
            Click a county to see its hospitals.
          </div>
          <Table
                  class="w-full"
                  maxRows={50}
                  columns={countyTableColumns}
                  data={$activeCounties}
                  sortField="confirmed"
                  sortDescending={true}>
            <tr
                    slot="row"
                    let:row
                    let:index
                    class:bg-gray-100={(index & 1) === 1}
                    class="cursor-pointer hover:bg-gray-200"
                    on:click={() => loadHospitals(row.county.fips)}>
              <td class="p-1 align-top {cellClass} font-medium text-gray-900">
                {row.county.county_name}, {row.county.state_name}
              </td>
              <td class="p-1 align-top {cellClass} text-right">
                {numberFormat(row.confirmed)}
              </td>
              <td class="p-1 align-top {cellClass} text-right">
                {numberFormat(row.deaths)}
              </td>
              <td class="p-1 align-top {cellClass} text-right">
                {decimalFormat(row.county.icu_beds_100)}
              </td>
              <!-- No recovery numbers for USA in our data yet -->
              <!-- <td>{numberFormat(row.recovered)}</td> -->
            </tr>
          </Table>
        {:else if currentView === VIEW_HOSPITAL}
          <div class="flex px-2 justify-between">
            <Button
                    class="mr-2"
                    size="sm"
                    on:click={() => (currentView = VIEW_COUNTIES)}>
              Back to Counties
            </Button>
            <span class="ml-auto text-right">
              Click a hospital to see its scorecard.
            </span>
          </div>
          {#await currentHospitals}
            <div style="height:1000px" class="px-2 mt-2">
              Loading Hospitals...
            </div>
          {:then hospitals}
            <ul class="mt-2">
              {#each hospitals as hospital, index}
                <li
                        class:border-t={index > 0}
                        class="cursor-pointer block hover:bg-gray-50 focus:outline-none
                focus:bg-gray-50 transition duration-150 ease-in-out
                border-gray-200"
                        on:click={() => showHospitalScoreCard(hospital)}>

                  <div class="px-4 py-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <div class="text-sm md:text-md leading-5 font-medium truncate text-primary-600">
                        {hospital.name}<br/>
                        {hospital.provider_business_practice_location_address_city_name},
                        {hospital.provider_business_practice_location_address_state_name}
                      </div>
                      <div class="ml-2 flex-shrink-0 flex">
                        <div class="text-right">
                          <p class="text-2xl leading-8 font-extrabold text-red-600 sm:text-3xl sm:leading-9">
                            { hospital.impactScore ? numberFormat(hospital.impactScore) : 'N/A' }
                          </p>
                          <p class="text-base leading-6 font-medium text-gray-500">
                            Impact Score
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="overflow-hidden">
                      <div class="-mx-4 flex flex-wrap">
<!--                        <div class="px-4 pt-8">-->
<!--                          <p class="text-md leading-8 font-extrabold text-blue-600 sm:leading-9">-->
<!--                            { hospital.profitability ? numberFormat(hospital.profitability) : 'N/A' }-->
<!--                          </p>-->
<!--                          <p class="text-base leading-6 font-medium text-gray-500">-->
<!--                            Profitability-->
<!--                          </p>-->
<!--                        </div>-->
<!--                        <div class="px-4 pt-8">-->
<!--                          <p class="text-md leading-8 font-extrabold text-blue-600 sm:leading-9">-->
<!--                            { hospital.county.impact ? numberFormat(hospital.county.impact) : 'N/A' }-->
<!--                          </p>-->
<!--                          <p class="text-base leading-6 font-medium text-gray-500">-->
<!--                            County Impact-->
<!--                          </p>-->
<!--                        </div>-->
                        <div class="px-4 pt-8">
                          <p class="text-md leading-8 font-extrabold text-blue-600 sm:leading-9">
                            {hospital.geographic_classification || 'N/A'}
                          </p>
                          <p class="text-base leading-6 font-medium text-gray-500">
                            Geography
                          </p>
                        </div>
                        <div class="px-4 pt-8">
                          <p class="text-md leading-8 font-extrabold text-blue-600 sm:leading-9">
                            {hospital.report.key_metrics.care.icu_beds || 0}
                          </p>
                          <p class="text-base leading-6 font-medium text-gray-500">
                            ICU Beds
                          </p>
                        </div>
                        <div class="px-4 pt-8">
                          <p class="text-md leading-8 font-extrabold text-blue-600 sm:leading-9">
                            {hospital.type_of_control}
                          </p>
                          <p class="text-base leading-6 font-medium text-gray-500">
                            Control
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              {/each}
            </ul>
          {:catch}
            Sorry, we encountered an error loading this county's hospitals.
          {/await}
        {/if}
      </div>
    </div>

    {#if currentHospital && showHospitalModal}
      <Modal
              width="w-3/4"
              closeButton={true}
              showHeader={false}
              showFooter={false}
              on:cancel={() => (showHospitalModal = false)}>
        <HospitalScorecard hospital={currentHospital} />
      </Modal>
    {/if}
  </div>
</div>
