<script>
  import { onMount } from 'svelte';
  import { format as formatNumber } from 'd3';
  import numeral from 'numeral';
  import DashboardBox from './DashboardBox.svelte';
  import { range, get, map } from 'lodash-es';

  const percentFormat = formatNumber('.2%');
  const numberFormat = formatNumber(',.0f');
  const currencyFormat = (num) => numeral(num).format('($ 0.00 a)');
  const decimalFormat = formatNumber(',.2f');

  export let hospital;

  /**
     * @param hospital
            {
  "_id": "20e8a90f-d44f-48b5-af0f-6272fa228255",
  "fips": "06037",
  "geographic_classification": "Urban",
  "name": "AHMC GREATER EL MONTE COMMUNITY",
  "primary_taxonomy": "282N00000X",
  "provider_business_practice_location_address_city_name": "SOUTH EL MONTE",
  "provider_business_practice_location_address_postal_code": "91733",
  "provider_business_practice_location_address_state_name": "CA",
  "provider_first_line_business_practice_location_address": "1701 SANTA ANITA AVE",
  "report": {
    "cost_reporting_period": {
      "from": "2018-07-01T00:00:00.000Z",
      "to": "2019-06-30T00:00:00.000Z"
    },
    "key_metrics": {
      "care": {
        "hcahps_score": "2",
        "icu_beds": 10
      },
      "financial": {
        "bad_debt": 6099193,
        "cash_on_hand": 34752187,
        "current_assets": 41977626,
        "current_liabilities": 22527240,
        "depreciation_expense": 302094,
        "inventory": 662582,
        "net_income": 6127423,
        "operating_income": 5662991,
        "total_assets": 44533654,
        "total_liabilities": 23359791,
        "total_operating_expense": 68520029,
        "total_operating_revenue": 74183020
      },
      "operations": {
        "discharges": 3144,
        "hospital_inpatient_days": 14772,
        "icu_days": 2310
      }
    },
    "number_of_beds": {
      "hospital": 94,
      "icu": 10
    },
    "total_discharges": 3144,
    "total_inpatient_days": 14772
  },
  "type_of_control": "Proprietary, Corporation"
}
     */
  function showHCAHPS() {
    let stars = get(hospital, 'report.key_metrics.care.hcahps_score');
    if (!stars || stars === 'Not Available') {
      return 'N/A';
    }
    return map(range(stars), (val) => {
      return '⭐';
    }).join(' ');
  }
</script>

<h3 class="text-center text-md sm:text-lg leading-6 font-medium text-gray-900">
  {hospital.name}
  <p class="mt-1 text-sm leading-5 text-gray-500">
    {hospital.provider_business_practice_location_address_city_name}, {hospital.provider_business_practice_location_address_state_name}
  </p>
  <p class="text-sm">
    This data was sourced from Provider Cost Reporting Information System,
    maintained by Centers for Medicare & Medicaid Services.
    <sup>1</sup>

  </p>
</h3>

<div class="mt-4 pb-8 bg-white">
  <div class="relative">
    <div class="absolute inset-0 h-1/2 bg-gray-50" />
    <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="max-w-4xl mx-auto">
        <div class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
          <DashboardBox>
            <span slot="value">
              {hospital.score.financials.operating_margin && isFinite(hospital.score.financials.operating_margin) ? percentFormat(hospital.score.financials.operating_margin) : 'N/A'}
            </span>
            <span slot="label">
              Operating margin
              <sup>2</sup>
            </span>
          </DashboardBox>
          <DashboardBox>
            <span slot="value">
              {hospital.score.financials.days_cash_on_hand ? numberFormat(hospital.score.financials.days_cash_on_hand) : 'N/A'}
            </span>
            <span slot="label">
              Days cash on hand
              <sup>3</sup>
            </span>
          </DashboardBox>
          <DashboardBox>
            <span slot="value">
              {hospital.score.financials.net_working_capital ? currencyFormat(hospital.score.financials.net_working_capital) : 'N/A'}
            </span>
            <span slot="label">
              Net working capital
              <sup>4</sup>
            </span>
          </DashboardBox>
          <DashboardBox>
            <span slot="value">
              {hospital.score.financials.debt_to_net_assets ? decimalFormat(hospital.score.financials.debt_to_net_assets) : 'N/A'}
            </span>
            <span slot="label">
              Debt-to-net assets
              <sup>5</sup>
            </span>
          </DashboardBox>
          <DashboardBox>
            <span slot="value">{showHCAHPS()}</span>
            <span slot="label">
              HCAHPS Star rating
              <sup>6</sup>
            </span>
          </DashboardBox>
          <DashboardBox>
            <span slot="value">
              {hospital.score.operations.alos ? decimalFormat(hospital.score.operations.alos) : 'N/A'}
            </span>
            <span slot="label">
              Average length of stay
              <sup>7</sup>
            </span>
          </DashboardBox>
          <DashboardBox>
            <span slot="value">
              {hospital.score.operations.icu_adc ? numberFormat(hospital.score.operations.icu_adc) : 'N/A'}
            </span>
            <span slot="label">
              ICU average daily census
              <sup>8</sup>
            </span>
          </DashboardBox>
        </div>
      </div>

      <ol style="list-style: decimal;" class="py-6 text-xs text-gray-500">
        <li>
          Medicare-certified institutional providers are required to submit an
          annual cost report to a Medicare Administrative Contractor (MAC). The
          cost report contains provider information such as facility
          characteristics, utilization data, cost and charges by cost center (in
          total and for Medicare), Medicare settlement data, and financial
          statement data.
        </li>
        <li>
          The operating margin measures how much profit a company makes on a
          dollar of sales, after paying for variable costs of production, such
          as wages and raw materials, but before paying interest or tax.
        </li>
        <li>
          Days cash on hand is the number of days that an organization can
          continue to pay its operating expenses, given the amount of cash
          available. This metric excludes market securities/temporary investments due to the volatility in the markets.
        </li>
        <li>
          Net working capital is the aggregate amount of all current assets and
          current liabilities. It is used to measure the short-term liquidity of
          a business.
        </li>
        <li>
          Total-debt-to-total-assets is a leverage ratio that defines the total
          amount of debt relative to assets. The higher the ratio, the higher
          the degree of leverage (DoL) and, consequently, financial risk.
        </li>
        <li>
          HCAHPS (pronounced "H-caps"), also known as the CAHPS Hospital Survey,
          is a survey instrument and data collection methodology for measuring
          patients' perceptions of their hospital experience.
        </li>
        <li>
          Average length of stay refers to the average number of days that
          patients spend in hospital.
        </li>
        <li>
          ICU average daily census is the average number of patients per day in
          the ICU over a year.
        </li>
      </ol>
    </div>
  </div>
</div>
