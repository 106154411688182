export default function createCompositeScore(hospital) {
  let scoreComponents = {
    financials: {
      ...hospital.report.key_metrics.financial,
      net_working_capital:
        hospital.report.key_metrics.financial.current_assets -
        hospital.report.key_metrics.financial.current_liabilities,
      operating_margin:
        (hospital.report.key_metrics.financial.total_operating_revenue -
          hospital.report.key_metrics.financial.total_operating_expense) /
        hospital.report.key_metrics.financial.total_operating_revenue,
      days_cash_on_hand:
        hospital.report.key_metrics.financial.cash_on_hand /
        ((hospital.report.key_metrics.financial.total_operating_expense -
          hospital.report.key_metrics.financial.depreciation_expense) /
          365),
      quick_ratio:
        (hospital.report.key_metrics.financial.current_assets -
          hospital.report.key_metrics.financial.inventory) /
        hospital.report.key_metrics.financial.current_liabilities,
      debt_to_net_assets:
        hospital.report.key_metrics.financial.total_liabilities /
        (hospital.report.key_metrics.financial.current_assets -
          hospital.report.key_metrics.financial.total_liabilities),
    },
    operations: {
      ...hospital.report.key_metrics.operations,
    },
    care: {
      ...hospital.report.key_metrics.care,
    },
  };
  scoreComponents.zScore = {
    a:
      scoreComponents.financials.net_working_capital /
      scoreComponents.financials.total_assets,
    b:
      scoreComponents.financials.net_income /
      scoreComponents.financials.total_assets,
  };
  scoreComponents.operations = {
    alos:
      scoreComponents.operations.hospital_inpatient_days /
      scoreComponents.operations.discharges,
    adc: scoreComponents.operations.hospital_inpatient_days / 365,
    icu_adc: scoreComponents.operations.icu_days / 365,
  };

  return scoreComponents;
}
